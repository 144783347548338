body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.d-flex {
  display: flex !important;
}

.bold {
  font-weight: bold !important;
}

.menu-item {
  padding-top: 4px;
  padding-bottom: 4px;
}

.icon-style {
  color: '#7D7D7D';
}

.small-icon-style {
  font-size: 18px !important;
}

.padding-0 {
  padding: 0px;
}

.text-align-left {
  text-align: left;
}

.margin-left-0 {
  margin-left: 0px;
}

.full-width {
  width: '100%';
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* * {
  font-size: 80%;
} */

#highlighted-button:hover::before,
#highlighted-button:focus::before {
  opacity: 1;
}

#highlighted-button::after {
  content: '';
  border-radius: 100%;
  border: 6px solid #00ffcb;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

#highlighted-button:hover::after,
#highlighted-button:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 83px;
    height: 83px;
    opacity: 1;
  }
  100% {
    width: 108px;
    height: 108px;
    opacity: 0;
  }
}

@media print {
  #mainContainerToolbar,
  #mainContainerDrawer,
  #mainContainerFooter {
    display: none;
  }
}
